import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { Button } from "@progress/kendo-react-buttons";
import { Input } from "@progress/kendo-react-inputs";
import { login } from "redux/features/user/actions";
import { toast } from "react-toastify";
import Loader from "components/loader";
import "./formLogin.scss";
import { useCustomLocalization } from "utils";

import defaultMessages from 'i18n/en.json';
import {setLanguage} from "../../../../redux/features/user";
import {DropDownList} from "@progress/kendo-react-dropdowns";

const useLocalizedMessages = () => {
  const [toLanguageString] = useCustomLocalization();
  return {
    inputUsernameLabel: toLanguageString(
      'metislab.frontend.components.login.components.inputUsernameLabel',

      defaultMessages.metislab.frontend.components.login.components.inputUsernameLabel),
    languageSelected: toLanguageString(
        "metislab.frontend.components.user.components.section.language.languageSelected",
        defaultMessages.metislab.frontend.components.user.components.section
            .language.languageSelected
    ),
    inputUsernamePlaceholder: toLanguageString(
      'metislab.frontend.components.login.components.inputUsernamePlaceholder',
      defaultMessages.metislab.frontend.components.login.components.inputUsernamePlaceholder),
    inputPasswordLabel: toLanguageString(
      'metislab.frontend.components.login.components.inputPasswordLabel',
      defaultMessages.metislab.frontend.components.login.components.inputPasswordLabel),
    inputPasswordPlaceholder: toLanguageString(
      'metislab.frontend.components.login.components.inputPasswordPlaceholder',
      defaultMessages.metislab.frontend.components.login.components.inputPasswordPlaceholder),  
    linkLabel: toLanguageString(
      'metislab.frontend.components.login.components.linkLabel',
      defaultMessages.metislab.frontend.components.login.components.linkLabel),
    loginButtonLabel: toLanguageString(
      'metislab.frontend.components.login.components.loginButtonLabel',
      defaultMessages.metislab.frontend.components.login.components.loginButtonLabel),
    languageEnglish: toLanguageString(
        "metislab.frontend.components.user.components.section.language.languageEnglish",
        defaultMessages.metislab.frontend.components.user.components.section
            .language.languageEnglish
    ),
    languageItalian: toLanguageString(
        "metislab.frontend.components.user.components.section.language.languageItalian",
        defaultMessages.metislab.frontend.components.user.components.section
            .language.languageItalian
    ),
  };
}

const FormLogin = () => {
  const {
    inputUsernameLabel,
    languageSelected,
    inputUsernamePlaceholder,
    inputPasswordLabel,
    inputPasswordPlaceholder,
    linkLabel,
    loginButtonLabel,
    languageEnglish,
    languageItalian
  } = useLocalizedMessages();

  const location = useLocation();

  const INITIALVALUES = {
    username: location.state?.username || "",
    password: "",
  };
  
  const loginSchema = Yup.object().shape({
    username: Yup.string().required("Required"),
    password: Yup.string().required("Required"),
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const loading = useSelector( (state) => state.user.loading);

  const [passwordVisible, setPasswordVisible] = useState(false);

  const handleClickLang = (lang) => {
    dispatch(setLanguage(lang))
  }


  const language = useSelector( (state) => state.user.language);

  const localLanguage = JSON.parse(localStorage.getItem('lang'))

  const togglePasswordVisibility = (e) => {
    e.preventDefault();
    setPasswordVisible((prevState) => !prevState);
  };

  const languagesAvailable = [
    { label: languageEnglish, key: "en-US" },
    { label: languageItalian, key: "it-IT" },
  ];
  return (
    <Formik
      initialValues={INITIALVALUES}
      onSubmit={(values) => {
        const data = {
          ...values,
          remember: true,
        };
        dispatch(login({ navigate, toast, data }));
      }}
      validationSchema={loginSchema}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
        } = props;

        return (
          <form 
            className="c-form c-form--login" 
            onSubmit={handleSubmit}
            onKeyDown={ (e) => {
              if (e.key === "Enter" && e.target.tagName === "INPUT") {
                e.preventDefault();
                handleSubmit();
              }
            }}
          >
            <div className="c-fieldset">
              <header className="c-fieldset__header">
                <h1 className="c-fieldset__title t-title t-title--1">Login</h1>
              </header>
              <div className="c-fieldset__main">
                <div className="c-form-field">
                  <label htmlFor="username">{inputUsernameLabel}</label>
                  <Input
                    name="username"
                    placeholder={inputUsernamePlaceholder}
                    autoComplete="username"
                    value={values.username}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.username && touched.username
                        ? "text-input error"
                        : "text-input"
                    }
                  />
                  {errors.username && touched.username && (
                    <div className="input-feedback">{errors.username}</div>
                  )}
                </div>
                <div className="c-form-field">
                  <label htmlFor="password">{inputPasswordLabel}</label>
                  <Input
                    type={passwordVisible ? "text" : "password"}
                    name="password"
                    placeholder={inputPasswordPlaceholder}
                    autoComplete="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.password && touched.password
                        ? "text-input error"
                        : "text-input"
                    }
                  />
                  {errors.password && touched.password && (
                    <div className="input-feedback">{errors.password}</div>
                  )}
                  <Button
                    className="c-show-pw k-rounded-full"
                    icon="preview"
                    fillMode="flat"
                    size="small"
                    onClick={togglePasswordVisibility}
                  />
                </div>
                <Link className="c-link" to="password-recovery">
                  {linkLabel}
                </Link>
                <div className={"c-form-field"} style={{marginTop: '24px'}}>
                  <label htmlFor="select-language">{languageSelected}</label>
                  <DropDownList
                      className="c-dropdown-language"
                      name="select-language"
                      data={languagesAvailable}
                      textField="label"
                      dataItemKey="key"
                      fillMode="outline"
                      value={languagesAvailable.find(el => el.key === language || el.key === localLanguage ) ?? null}
                      onChange={(e) => {
                        handleClickLang(e.target.value.key)
                      }}
                      popupSettings={{popupClass: "c-filter-content c-order-tag-content", width: 120}}
                  />
                </div>
              </div>

              <footer className="c-fieldset__footer">
                <Button
                  icon={loading ? null : "arrow-chevron-right"}
                  fillMode="solid"
                  dir="rtl"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? <Loader /> : loginButtonLabel}
                </Button>
              </footer>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default FormLogin;
